import React, { lazy, Suspense } from 'react'

const LazySep = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'SepNormalRelDblFullHorizontal1Lazy' */ './SepNormalRelDblFullHorizontal1'))


const SepNormalRelDblFullHorizontal1Lazy = (props) => {

    return (
        <Suspense
        // fallback="...loading sep"
        >
            <LazySep {...props} />
        </Suspense>
    )
}

export default SepNormalRelDblFullHorizontal1Lazy