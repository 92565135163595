import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";
import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";
import { POLYGON_RECTANGLE_10_POINTS_RIGHT, POLYGON_STAR } from "../../../utils/clipPathsConstants";
import SepNormalRelDblFullHorizontal1Lazy from "../../_shared/separators/SepNormalRelDblFullHorizontal1Lazy";

const LazyExcelPraceReactMultiCarouselLightGallery = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'ExcelPraceReactMultiCarouselLightGalleryLazy' */ './ExcelPraceReactMultiCarousel-LightGallery'));


const FullDown = () => {
  const deviceSize = useBreakpoint()


  return (
    <AnimateScrollIntoViewport
      durationIn={0.5}
      durationOut={0.2}
      animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
      exitFramer={{ clipPath: POLYGON_STAR, }}
      styleToMerge={{ clipPath: POLYGON_STAR, }}
      name="prog-FullDown"
      key="prog-FullDown"
    >
      <SepNormalRelDblFullHorizontal1Lazy />
      <Container
        maxWidth="none"
        sx={{
          my: 0,
          p: [ 0.5, 1, 2, 3 ],
        }}
      >
        <Container>
          <Suspense>
            <LazyExcelPraceReactMultiCarouselLightGallery deviceType={deviceSize} />
          </Suspense>
        </Container>
      </Container>

    </AnimateScrollIntoViewport>
  );
};

FullDown.propTypes = {
  children: PropTypes.node,
};

export default FullDown;
